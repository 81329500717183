* { transition: all 0.5s ease; }

iframe#nftpayIframe { width: auto; min-height: 510px; border: none; margin-top: 10px; display:none; margin-left: auto; margin-right: auto; }

.CCcenter{ display: inline-block; }

.spotHighlight { color:#ff006e; font-weight:bold; }

crossmint-pay-button button img { display:none !important; }

.xmint-btn::part(button) { margin-top: 10px; padding-top: 8px; padding-bottom: 10px; background: #000000; color: white; border: initial; padding: 8px 20px 10px 20px; border-radius: 15px; transition: transform 200ms ease; font-weight: 100; font-size: 20px; font-family: 'Righteous', sans-serif; box-shadow: 0px 0px 10px 0px rgba(255, 0, 110.00000000000006, 0.6); font-weight: 500; letter-spacing: 1.3px; fill: #FFFFFF; color: #FFFFFF; background-color: #FF006E; border-style: solid; border-width: 2px 2px 2px 2px; border-color: #FF006E; border-radius: 50px 50px 50px 50px; padding: 20px 30px 20px 30px; transition: all 0.5s ease; }

.xmint-btn::part(contentParagraph) { color: white; font-size: 1.25rem; }

.xmint-btn::part(contentParagraph) { display: none;  }

 .xmint-btn::part(button):after { content: "Get Started" }

 .xmint-btn::part(button):hover{ color: white; transform: translate(0px, -2px); background-color: #FF006E00; }

p.welcomeSocial { margin: 0; padding: 0; text-align: center; }

p.welcomeSocial img { width:30px; }

p.joinSocial a { color:red; }

#prepare p.promoMessage1 { color: #ffffff; font-size: 100%; margin-bottom: 7px; font-family: 'Righteous', sans-serif; }

p.promoMessage2 { margin-top: 0; font-family: 'Righteous', sans-serif; }

p.promoMessage2 a { color: #ffffff; text-decoration: underline; font-size: 90%; }

input#promoArea { font-size: 20px; border-radius: 15px; padding: 8px; position: relative; top: 2px; width: 191px; margin-right: 20px; text-align: center; }

input#promoArea::placeholder { color: #D7932E; }

 p.mintBoxRightMessage { font-family: 'Righteous', sans-serif; font-size: 25px; color: #ffffff; margin-top: 0; }

.sub-text { font-size: 25px; }

div.clear { background: none; border: 0; clear: both; display: block; float: none; font-size: 0; list-style: none; margin: 0; padding: 0; overflow: hidden; visibility: hidden; width: 0; height: 0; }

.headerLeft .logoape{ width: 100px; margin-right: 10px; transform: scaleX(-1); }

button { cursor: pointer; }

.buttonMessage{ font-size: 85%; color: white; font-family: 'Righteous', sans-serif; }

.buttonMessage a { color: #ff006e; text-decoration: underline; }

.bottomLink{ text-align: center; }

.bottomLink a { color: #ff006e; margin-top: 0; margin-bottom: 10px; font-family: "Inter", sans-serif; font-optical-sizing: auto; font-weight: 300; }

body { background:#000000; color: black; margin: 0; padding: 0; font-family: "Roboto", sans-serif; font-weight: 300; }

.section-background-overlay { border-bottom: 5px solid #ea379d; background-color: #000000; background-image: url("https://fawc.house/wp-content/uploads/2024/04/bg-gradient.png"); background-position: 50% 5vh; background-repeat: no-repeat; background-size: cover; }

.fawc_overlay_class { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 9999; background-color: #000; color: #fff; padding: 2% 0; text-align: center; min-height: 1000px; }

#welcomeMessage { background: #ff006e; max-width: 500px; min-height: 300px; margin: 2% auto 5%; padding: 40px; font-size: 110%; font-weight: 100; border-radius: 40px; font-family: "Lato",sans-serif; display: block; text-align: left; line-height: 150%; }

#welcomeMessage img.wmdst { float: right; width: 50%; }

#welcomeMessage a{ color:#e8f702; }

.warning{ color:yellow; font-weight:bold; }

#fawc_overlay_id input { width: 250px; font-size: 15px; padding: 5px 10px; border: 4px solid #ff006e; border-radius: 15px; }

#fawc_overlay_id button { background: #ff006e; font-size: 15px; padding: 9px 13px; border: none; border-radius: 15px; color: #fff; }

a { color: white; text-decoration: none; }

.mintBoxLeft a { color: #ff006e; }

.white { color: white; }

.header img.thelogo{ position: relative; top: 0px; width:130px; } 

.noCollectionSpacer { height:30px; }

.smaller { font-size: 80%; }

.newIntro { color: white; padding: 20px; width: 90%; overflow: auto; margin: 0 auto 20px auto; max-width: 1200px; }

.newIntro h2 { font-size:400%; margin-bottom:0; }

.fixcaps { text-transform: initial !important; }

div.AmountWrap { display: flex; align-items: center; justify-content: center; }

div.MintBtn { margin: 2rem 0; background: #3f5d13 !important; color: #0F0F0F !important; cursor: pointer; text-transform: uppercase; justify-self: self-start; border: 0 !important; font-size: 1.5vw; transition: all .2s; }

.MintBtn:hover { color: white !important; background: black !important; transition: all .2s; }

div.SoldFont { font-size: 1.4rem; color: white; }

img.CollectionImage { width: 65%; border-radius: 15px; }

div.LoadingFont { font-size: 1.5vw; color: white; }

#pageLogo img { width: 100%; }

#pageLogo { width: 100px; height: 100px; position: absolute; top: 15px; left: 9%; }

#saleStatus { font-size: 150%; color: #fff; text-decoration: overline; text-decoration-color: #00ffa7; text-decoration-thickness: from-font; text-decoration-style: wavy; }

#theStatus { padding-left: 15px; color: white; }

.pageHighlightColor { color: #3f5d13; }

.pageHighlightColor2 { color: #6a624a; }

#branding { padding-top: 20px; }

#branding img { border: 2px solid black; -webkit-box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58); -moz-box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58); box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58); }

#navButtons { margin-top: 20px; }

img.navIcons { position: relative; top: 3px; left: -7px; width: 20px; height: 20px; }

.logo-wallet { width: 90px; height: 90px; }

.walletName{ font-weight: 100; font-size: 150%; vertical-align: top; display: block; }

div.ReactModal__Content.ReactModal__Content--after-open .providerChoices:hover { background:#ff006e; color:white; }

#DAppArea button { margin-top:10px; }

#totalPrice { margin-top:15px; margin-bottom:15px; font-family: 'Righteous', sans-serif; }

#prepare button, #DAppArea button { background: #000000; color: white; border: initial; padding: 8px 20px 10px 20px; border-radius: 15px; transition: transform 200ms ease; font-weight: 100; font-size: 20px; font-family: 'Righteous', sans-serif; box-shadow: 0px 0px 10px 0px rgba(255, 0, 110.00000000000006, 0.6); font-weight: 500; letter-spacing: 1.3px; fill: #FFFFFF; color: #FFFFFF; background-color: #FF006E; border-style: solid; border-width: 2px 2px 2px 2px; border-color: #FF006E; border-radius: 50px 50px 50px 50px; padding: 20px 30px 20px 30px; transition: all 0.5s ease; }

input#sliderVal { margin-bottom:15px; }

#DAppArea .quantityQuestion { font-family: 'Righteous', sans-serif; margin-bottom:6px; font-size: 130%; }

hr { background: #ffffff; width: 30%; margin-top: 27px; height: 3px; border-radius: 10px; }

#prepare button:hover, #DAppArea button:hover { color: white; transform: translate(0px, -2px); background-color: #FF006E00; }

div.ReactModal__Content.ReactModal__Content--after-open { background: #000000 !important; text-align: center; border-radius: 15px !important; padding-top: 10px !important; border: 1px gray solid !important; box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.65); -webkit-box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.65); }

div.ReactModal__Content.ReactModal__Content--after-open h2 { color: white !important; font-size: 160%; margin-bottom: 10px; margin-top: 6px; font-family: 'Righteous', sans-serif; }

div.ReactModal__Content.ReactModal__Content--after-open button { background: white; border: initial; padding: 5px; border-radius: 15px; margin-bottom: 15px; }

div.ReactModal__Content.ReactModal__Content--after-open .providerChoices { width: 100%; margin-bottom: 10px; background: #fff; border: 1px solid gray; border-radius: 15px; margin-top: 10px; }

.topBanner { width:100%; padding: 0px; padding-bottom: 0; background-attachment: fixed; text-align: center; }

.topBannerWrapper { width:90%; max-width: 1200px; margin-left: auto; margin-right: auto; }

.topBannerLeft { float: left; width: 20%; }

.topBannerRight { width: 80%; float: right; margin: 0; padding: 0; text-align: right; padding-top: 4%; }

 .topBannerRight a { color: #3f5d13; padding-left: 9px; padding-right: 9px; font-size: 100%; background: #ffe80069; margin-left: 15px; border-radius: 15px; } .topBannerRight a:hover { color: #ffffff; background: #3f5d13; }

.topBannerButton { padding: 3px 10px 3px 10px; border-radius: 15px; box-shadow: 3px 3px 11px rgb(0 0 0 / 40%); -webkit-box-shadow: 3px 3px 11px rgb(0 0 0 / 40%); -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4); white-space: nowrap; background: black; margin: 20px 20px 20px 20px; }

.topBannerButton:hover { background: #d54aff; }

.mintBoxRight h2 { color: #ff006e; margin-top: 0; margin-bottom: 10px; font-family: "Inter", sans-serif; font-optical-sizing: auto; font-weight: 300; }

#DAppArea h2 { color: white; font-size: 275%; margin-top: 0; font-weight: 100; }

#DAppArea h3 { color: #ffffff; font-style: normal; font-size: 25px; font-weight: 700; margin-bottom: 0; font-family: 'Righteous', sans-serif; }

.white { color: white; }

.qty { color: #364050; }

button:hover .qty { color: white; }

.welcome { text-align: center; font-size: 135%; margin-left: 10%; margin-right: 10%; margin-bottom: 30px; MARGIN-TOP: 0; letter-spacing: 1px; }

span.copyrightNotice { font-size: 65%; }

.droplogo { text-align:center; }

.droplogo img { width: 150px; }

.allButMobile { text-align: center; }

.allButMobile img, img.mobileOnly { max-width: 90%; border-radius: 15px; width: 90%; margin-bottom: 15px; -webkit-box-shadow: 3px 3px 10px 3px #7d7a7a; -moz-box-shadow: 3px 3px 10px 3px #7d7a7a; box-shadow: 3px 3px 10px 3px #7d7a7a; /*border: 3px solid #1c58c0;*/}

div.mintBoxWrapper { color: white; padding: 20px; width: 90%; display: flex; overflow: auto; margin: 0 auto 20px auto; max-width: 1200px; }

div.aboveMintBox { width: 90%; display: flex; overflow: auto; margin: 15px auto 20px auto; max-width: 1200px; }

.supplyInfo { font-size: 125%; }

div.mintBoxLeft { width: 40%; padding-left: 0; display: block; justify-content: center; align-items: start; align-content: center; border: 1px solid #99175c; border-radius: 30px; padding: 36px; -webkit-box-shadow: 0px 4px 15px 0px #000000; box-shadow: 0px 4px 15px 0px #000000; }

div.mintBoxRight { width: 56%; padding: 20px; float: left; text-align: center; padding: 10px 5% 0 5%; }

.mobileOnly { display: none; }

#prepare p { font-size: 15px; width: 50%; margin-left: auto; margin-right: auto; color: #000000; line-height: 20px; font-family: 'Righteous', sans-serif; }

.headTextLinks a { font-weight: bold; padding-left: 20px; padding-right: 20px; white-space: nowrap; text-decoration: none; line-height: 2;  }

.headTextLinks a:hover { color: white; transform: translate(0px, -2px); background-color: #FF006E00; text-decoration: underline; } 
.bottomSamples { margin: 0 auto 80px auto; max-width: 1200px; }

.bottomSamples img { width:100%; border-radius: 10px; }

.header { padding-top: 20px; max-width: 1200px; width: 90%; margin-left: auto; margin-right: auto; } 
.headerLeft { float:left; width:30%; text-align:left; }

.headerRight { float: right; width: 60%; padding-right: 5%; padding-top: 35px; text-align: right; } 
a.pageButton.logowords { color:white; font-weight: 100; font-size: 20px;padding-left: 20px; padding-right: 20px; padding-top: 6px; }

.footer { border-top: 1px solid black; width: 100%; padding: 0; text-align: center; color: black; padding-bottom: 8px; padding-top: 7px; background: black; bottom : 0px; height : 40px; margin-top : 40px; text-align: center; vertical-align: middle; position:fixed; width:100%; }

 a.pageButton { color: white; padding: 5px 5px 5px 11px; margin: 0 2% 0 3%; white-space: nowrap; position: relative; transition: transform ease 200ms; display: inline-block; border-radius: 50%; border: 1px solid white; font-weight: 100;  }

a.pageButton:hover { background: #0949c9; color: white; transform: translate(0, -2px); }

.bannerButton { padding: 9px 13% 8px 13%; line-height: 250%; border-radius: 15px; box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4); -webkit-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4); -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4); white-space: nowrap; }

.bannerButton:hover { background: #d54aff; }

#HeaderButtons { margin-top: 20px; position: absolute; top: 1%; right: 6%; }

.header img { position: relative; top: 2px; left: -3px; width: 20px; }

.headerLeft img { width: 200px; }

.footer img { position: relative; top: 3px; left: -4px; width: 20px; }

.credits { background: #778384; text-align: center; font-size: 80%; letter-spacing: 1px; color: white; }

.credits a { color: black; }

.supplyInfo { font-weight:bold; }

.larger { font-size: 200%; font-weight: 100; letter-spacing: initial; }

input[type=range] { width: 30%; margin: 8px 0; background-color: transparent; -webkit-appearance: none; }

input[type=range]:focus { outline: none; }

input[type=range]::-webkit-slider-runnable-track { background: #ffffff; border: 1px solid #838585; border-radius: 15px; width: 100%; height: 6px; cursor: pointer; }

input[type=range]::-webkit-slider-thumb { margin-top: -9px; width: 33px; height: 22px; background: #ff006e; border: 4px solid #125d8d; border-radius: 15px; cursor: pointer; -webkit-appearance: none; }

input[type=range]:focus::-webkit-slider-runnable-track { background: #ffffff; }

input[type=range]::-moz-range-track { background: #ffffff; border: 1px solid #ff006e; border-radius: 15px; width: 100%; height: 6px; cursor: pointer; }

input[type=range]::-moz-range-thumb { width: 33px; height: 22px; background: #ff006e; border: 4px solid black; border-radius: 15px; cursor: pointer; }

input[type=range]::-ms-track { background: transparent; border-color: transparent; border-width: 12.1px 0; color: transparent; width: 100%; height: 6px; cursor: pointer; }

input[type=range]::-ms-fill-lower { background: #ebebeb; border: 1px solid #ff006e; border-radius: 50px; }

input[type=range]::-ms-fill-upper { background: #ffffff; border: 1px solid #ff006e; border-radius: 50px; }

input[type=range]::-ms-thumb { width: 33px; height: 22px; background: #ff006e; border: 4px solid black; border-radius: 15px; cursor: pointer; margin-top: 0px; }

input[type=range]:focus::-ms-fill-lower { background: #ffffff; }

input[type=range]:focus::-ms-fill-upper { background: #ffffff; }

.bottomMobile { display:none; }

@media (max-width: 1137px) { 

 .topBanner img { width: 175%; } 
 .headerLeft { width: 20%; padding-left: 2%; } 
 .headerRight { width: 75%; padding-right: 0%; }

}

@media (max-width: 800px) { 

    div.mintBoxLeft { width: 100%; padding: 0; display: none; } 
    div.mintBoxRight { width: 100%; padding: 20px; display: block; } 
    .mobileOnly { display: block; margin: 10px auto 20px auto; max-width: 60%; } 
    .topBanner { background: #ffffff; min-height: 80px; } 
    .footer { display:none; } 
    .topBannerRight, .topBannerLeft { width: 100%; float: initial; text-align: center; } 
    .topBanner img { width: 90%; } .allButMobile { display:none; } 
    a.pageButton { padding: 2px 2% 2px 2%; margin: 0 1% 0 1%; font-size: 75%; } 
    .header { height: 30px; padding-top: 30px; }
    .topBanner { min-height: 50px; }
    .headerLeft { width:100%; text-align:center; padding:0; display:block; }
    .headerRight { width:100%; text-align:center; padding:0; display:block; padding-top: 20px; }
    .headerRight img { top: 2px; left: 0px; width: 18px; }
    .headTextLinks a { display: block; }
    a.hiddenMobile { display:none; }
    .bottomMobile{ display:block; width: 100%; padding: 0; justify-content: center; align-items: start; align-content: center; border: 1px solid #99175c; border-radius: 30px; padding: 36px; -webkit-box-shadow: 0px 4px 15px 0px #000000; box-shadow: 0px 4px 15px 0px #000000; color:white; width:75%; margin-left:auto; margin-right:auto; } div.mintBoxWrapper { margin-bottom:0; }

}

